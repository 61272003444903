<template>
    <div class="container-wide">
        <div class="table-title">
            <div class="row">
                <div class="col-sm-4 text-white mb-3">
                    <h2>Karnet</h2>
                </div>
                <div class="col-sm-2">
                    <div class="form-floating">
                        <select type="date" class="form-control" id="woDeleted" v-model="carnetView" placeholder="Prikaz" @change="changeView">
                                <option value="MONTH_PER_DAY">Po danima</option>
                                <option value="YEAR_MONTH_PER_DAY">Po mesecima</option>
                                <option value="DAY">Dnevni</option>
                                <option value="MONTH">Mesecni sumiran</option>
                                <option value="YEAR">Godisnji</option>
                        </select>
                        <label for="woAgreedValue">Prikaz dana</label>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div v-if="carnetView == 'DAY'" class="form-floating">
                        <input type="date" class="form-control" id="woEndDate" v-model="selectedDate" placeholder="Period" @change="changeDate"  min="2021-07-15" :max="currentMoment.format('YYYY-MM-DD')"  required>
                        <label for="woEndDate">Dan</label>
                        <div class="invalid-feedback">
                            Unesite period
                        </div>
                    </div>
                    <div v-else-if="carnetView == 'MONTH_PER_DAY'" class="form-floating">
                        <div class="form-floating">
                            <select type="date" class="form-control" id="woDeleted" placeholder="Prikaz" @change="changeMPDView">
                                    <option value="WORK_DAYS">Radnih sati</option>
                            </select>
                            <label for="woAgreedValue">Prikaz dana</label>
                        </div>
                    </div>
                    <div v-else-if="carnetView == 'YEAR' || carnetView == 'YEAR_MONTH_PER_DAY'" class="form-floating">

                        <select id="dob" class="form-control" @change="changeDate" v-model="selectedYear">
                            <option value="0">Year:</option>
                            <option v-for="year in years" :key=year :value="year">{{ year }}</option>
                        </select>     
                        <label for="woEndDate">Godina</label>
                        <div class="invalid-feedback">
                            Unesite period
                        </div>
                    </div>
                </div>

                <div class="col-sm-3">
                    <div v-if="carnetView == 'MONTH' || carnetView == 'MONTH_PER_DAY'" class="form-floating">
                        <input type="month" class="form-control" id="woEndDate" v-model="selectedDate" placeholder="Period" @change="changeDate" min="2021-07" :max="currentMoment.format('YYYY-MM')" required>
                        <label for="woEndDate">Mesec</label>
                        <div class="invalid-feedback">
                            Unesite period
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div v-if="loading">
            <h5>
                <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
                Karnet se učitava <small>Sabiranje sati traje i do 15 sekundi</small>
            </h5>
        </div>

        <div class="col-lg-12 control-section" v-if="employees">
            <div v-if="carnetView == 'YEAR_MONTH_PER_DAY'">
                <ejs-grid
                    ref="employeesCarnetGrid"
                    :dataSource="employeesCarnet" 
                    class="sortingenabled carnet-grid"
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :filterSettings='filterSettings'
                    :allowResizing='true'
                    :allowReordering='true'
                    :queryCellInfo='queryCellInfo'
                    :resizeStop='resizeEvent'
                    :enablePersistence='true'
                    :actionComplete='actionCompleteEvent'
                    :created="createdTable"
                    :toolbar='toolbar'
                    :toolbarClick='clickHandler'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    :excelExportComplete='excelExportComplete'
                    :pdfExportComplete='pdfExportComplete'
                    >
                    <e-columns>
                        <e-column field='' headerText='#' :template="rowIndexTemplate" :width='widthStates[0]' textAlign='Right' :visible='visibleStates[0]' ></e-column>

                        <e-column field='id' headerText='ID' :width='widthStates[1]' textAlign='Left' :isPrimaryKey='true' :visible='visibleStates[1]' ></e-column>
                        <e-column field='user.username' :customAttributes="{class: 'sticky-column'}" headerText='Profil' :template='usernameTemplate' clipMode="EllipsisWithTooltip" :visible="visibleStates[2]" :width='widthStates[2]' textAlign='Left'></e-column>
                        <e-column field='user.last_name' headerText='Prezime' :width='widthStates[3]' :visible="visibleStates[3]"></e-column>
                        <e-column field='user.first_name' headerText='Ime' :width='widthStates[4]' :visible="visibleStates[4]"></e-column>
                        
                        <e-column field='work_end_date' headerText='Datum odjave' :width='widthStates[5]' :visible='visibleStates[5]'></e-column>
                        <e-column :valueAccessor='transportTypeAccessor' field='transport_type' headerText='Tip prevoza' :width='widthStates[6]' :visible='visibleStates[6]'></e-column>
                        <e-column :valueAccessor='roleAccessor' field='user.role' headerText='Uloga' :width='widthStates[7]' :visible='visibleStates[7]'></e-column>
                        

                    
                        <e-column field='carnet.0'  :columns='colMonth1' headerText='Jan' width='80' :visible='true'></e-column>
                        <e-column field='carnet.1'  :columns='colMonth2' headerText='Feb' width='80' :visible='true'></e-column>
                        <e-column field='carnet.2'  :columns='colMonth3' headerText='Mar' width='80' :visible='true'></e-column>
                        <e-column field='carnet.3'  :columns='colMonth4' headerText='Apr' width='80' :visible='true'></e-column>
                        <e-column field='carnet.4'  :columns='colMonth5' headerText='Maj' width='80' :visible='true'></e-column>
                        <e-column field='carnet.5'  :columns='colMonth6' headerText='Jun' width='80' :visible='true'></e-column>
                        <e-column field='carnet.6'  :columns='colMonth7' headerText='Jul' width='80' :visible='true'></e-column>
                        <e-column field='carnet.7'  :columns='colMonth8' headerText='Avg' width='80' :visible='true'></e-column>
                        <e-column field='carnet.8'  :columns='colMonth9' headerText='Sep' width='80' :visible='true'></e-column>
                        <e-column field='carnet.9'  :columns='colMonth10' headerText='Okt' width='80' :visible='true'></e-column>
                        <e-column field='carnet.10' :columns='colMonth11' headerText='Nov' width='80' :visible='true'></e-column>
                        <e-column field='carnet.11' :columns='colMonth12' headerText='Dec' width='80' :visible='true'></e-column>


                    </e-columns>

                </ejs-grid>
            </div>
            <div v-else>
                <ejs-grid
                    ref="employeesCarnetGrid"
                    :dataSource="employeesCarnet" 
                    class="sortingenabled carnet-grid"
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :filterSettings='filterSettings'
                    :allowResizing='true'
                    :allowReordering='true'
                    :queryCellInfo='queryCellInfo'
                    :resizeStop='resizeEvent'
                    :enablePersistence='true'
                    :actionComplete='actionCompleteEvent'
                    :created="createdTable"
                    :toolbar='toolbar'
                    :toolbarClick='clickHandler'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    >
                    <e-columns>
                        <e-column field='' headerText='#' :template="rowIndexTemplate" :width='widthStates[0]' textAlign='Right' :visible='visibleStates[0]' ></e-column>

                        <e-column field='id' headerText='ID' :width='widthStates[1]' textAlign='Left' :isPrimaryKey='true' :visible='visibleStates[1]' ></e-column>
                        <e-column field='user.username' headerText='Profil' :template='usernameTemplate' clipMode="EllipsisWithTooltip" :visible="visibleStates[2]" :width='widthStates[2]' textAlign='Left'></e-column>
                        <e-column field='user.last_name' :allowResizing='false' :customAttributes="{class: 'sticky-column'}" headerText='Prezime' :width='widthStates[3]' :visible="visibleStates[3]"></e-column>
                        <e-column field='user.first_name' :customAttributes="{class: ['sticky-column', 'left-150']}" headerText='Ime' :width='widthStates[4]' :visible="visibleStates[4]"></e-column>
                        
                        <e-column field='work_end_date' headerText='Datum odjave' :width='widthStates[5]' :visible='visibleStates[5]'></e-column>
                        <e-column :valueAccessor='transportTypeAccessor' field='transport_type' headerText='Tip prevoza' :width='widthStates[6]' :visible='visibleStates[6]'></e-column>
                        <e-column :valueAccessor='roleAccessor' field='user.role' headerText='Uloga' :width='widthStates[7]' :visible='visibleStates[7]'></e-column>
                        

                        <template v-if="perDayView">
                            <e-column v-for="dateIter, index in datesArray" :key="index" :field="'carnet.' + dateIter + '.work_hours'" :valueAccessor='hoursAccessor' type="number" :headerText='index + 1' width='70' :visible='true'></e-column>
                            <e-column field='carnet.sum_hours' headerText='Suma' width='100' :visible='true'></e-column>

                        </template>
                        <template v-if="sumView">
                            <e-column field='carnet.attendance_days' headerText='Radnih dana' width='150' :visible='true'></e-column>
                            <e-column field='carnet.attendance_hours' headerText='Radnih sati' width='150' :visible='true'></e-column>
                            <e-column field='carnet.catering_own' headerText='Samostalni obrok' width='150' :visible='true'></e-column>
                            <e-column field='carnet.catering_service' headerText='Ketering' width='150' :visible='true'></e-column>

                            <e-column field='carnet.off_days' headerText='Neradni dani' width='150' :visible='false'></e-column>
                            <e-column field='carnet.not_appeared' headerText='Izostanaka' width='150' :visible='true'></e-column>
                            <e-column field='carnet.absence.absence_vacation' headerText='Godisnji odmor' width='150' :visible='false'></e-column>
                            <e-column field='carnet.absence.absence_sickness' headerText='Bolovanje' width='150' :visible='false'></e-column>
                            <e-column field='carnet.absence.absence_other' headerText='Druga odsustva' width='150' :visible='false'></e-column>
                            <e-column field='carnet.absence.total' headerText='Ukupno odmora' width='150' :visible='true'></e-column>

                            <e-column field='carnet.approved' headerText='Urađenih poslova' width='150' :visible='false'></e-column>
                            <e-column field='carnet.rejected' headerText='Odbijenih poslova' width='150' :visible='false'></e-column>
                            <e-column field='carnet.ratio' headerText='Koeficijent' width='150' :visible='false'></e-column>
                        </template>

                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <template v-if="perDayView">
                                    <e-column v-for="dateIter, index in datesArray" :key="index" type="Sum" :field="'carnet.' + dateIter + '.work_hours'"></e-column>
                                </template>
                                <template v-else>
                                    <e-column type="Sum" field="carnet.attendance_days"></e-column>
                                    <e-column type="Sum" field="carnet.attendance_hours"></e-column>
                                    <e-column type="Sum" field="carnet.catering_own"></e-column>
                                    <e-column type="Sum" field="carnet.catering_service"></e-column>
                                    <e-column type="Sum" field="carnet.off_days"></e-column>
                                    <e-column type="Sum" field="carnet.absence.not_appeared"></e-column>
                                    <e-column type="Sum" field="carnet.absence.absence_vacation"></e-column>
                                    <e-column type="Sum" field="carnet.absence.absence_sickness"></e-column>
                                    <e-column type="Sum" field="carnet.absence.absence_other"></e-column>
                                    <e-column type="Sum" field="carnet.absence.total"></e-column>
                                </template>

                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </div>
            
        </div>

         
    </div>
</template>


<script>
    import EmployeesService from '@/service/EmployeesService.js';
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';

    import { Sort, Page, ColumnMenu, Filter, Group, Reorder, Resize, Freeze, Toolbar, PdfExport, ExcelExport, Aggregate } from "@syncfusion/ej2-vue-grids";
    import { PdfStandardFont, PdfFontFamily, PdfFontStyle } from '@syncfusion/ej2-pdf-export';

    export default {


        setup: {
            UtilService
        },
        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Reorder, Resize, Freeze, Toolbar, PdfExport, ExcelExport, Aggregate ]
        },

        data: function() {
            return {
                employees: null,
                employeesCarnet: null,

                selectedDate: UtilService.formatDatePattern(UtilService.getMoment(), "YYYY-MM"),
                selectedYear: UtilService.formatDatePattern(UtilService.getMoment(), "yyyy"),
                carnetView: "MONTH_PER_DAY",
                carnet: {},

                filterSettings: { type: "Excel" },
                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }
                },
            
                loading: true,

                visibleStates: [true, false, true, true, true, false, false, false],
                widthStates: [60, 40, 120, 150, 150, 150, 150, 150],


                toolbar: [

                    { text: 'Exprot Excel', tooltipText: 'Izvezi Excel', prefixIcon: 'e-excelexport', id: 'exportExcel', align: 'Right'},
                    // { text: 'Exprot PDF', tooltipText: 'Izvezi PDF', prefixIcon: 'e-pdfexport', id: 'exportPDF', align: 'Right'},
                    { text: 'Print', tooltipText: 'Štampaj', prefixIcon: 'e-print', id: 'print', align: 'Right'},
                    ],

                
                colMonth1: [
                    {
                        field: 'carnet.0',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.0',
                    //     headerText: 'RS',
                    //     width: 80,
                    // }
                ],

                colMonth2: [
                    {
                        field: 'carnet.1',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.1',
                    //     headerText: 'RS',
                    //     width: 80,
                    // }
                ],

                colMonth3: [
                    {
                        field: 'carnet.2',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.2',
                    //     headerText: 'RS',
                    //     width: 80,
                    // }
                ],

                colMonth4: [
                    {
                        field: 'carnet.3',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.3',
                    //     headerText: 'RS',
                    //     width: 80,
                    // } 
                ],

                colMonth5: [
                    {
                        field: 'carnet.4',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.4',
                    //     headerText: 'RS',
                    //     width: 80,
                    // }
                ],

                colMonth6: [
                    {
                        field: 'carnet.5',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.5',
                    //     headerText: 'RS',
                    //     width: 80,
                    // }
                ],

                colMonth7: [
                    {
                        field: 'carnet.6',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.6',
                    //     headerText: 'RS *',
                    //     width: 80,
                    // }
                ],

                colMonth8: [
                    {
                        field: 'carnet.7',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.7',
                    //     headerText: 'RS *',
                    //     width: 80,
                    // }
                ],

                colMonth9: [
                    {
                        field: 'carnet.8',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.8',
                    //     headerText: 'RS *',
                    //     width: 80,
                    // }
                ],

                colMonth10: [
                    {
                        field: 'carnet.9',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.9',
                    //     headerText: 'RS *',
                    //     width: 80,
                    // }
                ],

                colMonth11: [
                    {
                        field: 'carnet.10',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.10',
                    //     headerText: 'RS *',
                    //     width: 80,
                    // }
                ],

                colMonth12: [
                    {
                        field: 'carnet.11',
                        headerText: 'RS *',
                        width: 80,
                    },
                    // {
                    //     field: 'carnet.11',
                    //     headerText: 'RS *',
                    //     width: 80,
                    // }
                ],


                usernameTemplate: function() {
                    return {
                        template: {
                            extends: UsernameTemplate,
                            propsData: {
                                showAvatar: true,
                                showName: true,
                                showLastName: true,
                                showUsername: false,
                                profilePage: 'EmployeeCarnet',
                            },
                        }

                    };
                },
            };



            
        },  
        computed: {
            ...mapState([
                'allWorkOrders',
            ]),

            leftBoundDate() {
                return UtilService.addDate(UtilService.convertMoment(this.selected_date), -this.selected_days, 'day');
            },


            years () {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2019}, (value, index) => 2020 + index)
            },

            currentMoment() {
                return UtilService.getMoment();
            },

            perDayView() {
                if (this.carnetView == 'DAY')
                    return false;
                if (this.carnetView == 'MONTH')
                    return false;
                if (this.carnetView == 'MONTH_PER_DAY')
                    return true;
                if (this.carnetView == 'YEAR_MONTH_PER_DAY')
                    return false;
                if (this.carnetView == 'YEAR')
                    return false;
                return undefined;
            },
            sumView() {
                if (this.carnetView == 'DAY')
                    return true;
                if (this.carnetView == 'MONTH')
                    return true;
                if (this.carnetView == 'MONTH_PER_DAY')
                    return false;
                if (this.carnetView == 'YEAR_MONTH_PER_DAY')
                    return false;
                if (this.carnetView == 'YEAR')
                    return true;
                return undefined;
            },

            datesArray() {
                return this.intervalToDatesArray(this.getStartDate(), this.getEndDate())
            },
        },

        methods: {

            formatDate: UtilService.formatDate,
            translateRole: UtilService.translateRole,
            translateTransportType: UtilService.translateTransportType,
            intervalToDatesArray: UtilService.intervalToDatesArray,


            hoursAccessor(field, data) {
                try {
                    let strObject = field.split('.');
                    let obj = data[strObject[0]][strObject[1]][strObject[2]];
                    
                    let carnetData = data[strObject[0]][strObject[1]]
                    if (!carnetData.working_day || carnetData.absence.absence)
                        return obj > 0 ? obj : '';

                    return obj;
                }
                catch (error) {
                    return '.';
                }
            },

            roleAccessor(field, data) {
                return this.translateRole(data['user'].role);
            },

            transportTypeAccessor(field, data) {
                return this.translateTransportType(data['transport_type']);
            },

            statusAccessor(field, data) {
                return data['employee_status'] === 'ACTIVE' ? 'Aktivan' : 'Neaktivan'; 
            },

            async loadActiveEmployees() {
                await EmployeesService.getDeletedAllEmployees()
                .then(response => { this.employees = response.data; })
                .catch((error) => { alert(error.message); });
                console.log(2);
            },

            queryCellInfo(args) {
                try {
                    if (args.column.field.startsWith('carnet.') == true) {
                        let trimmed = args.column.field.slice(7);
                        let nextDot = trimmed.indexOf('.');
                        trimmed = trimmed.slice(0, nextDot);

                        if (args.data?.carnet == null) return;
                        let currentDay = args.data?.carnet[trimmed];

                        if (!currentDay) return

                        if (currentDay?.absence?.absence){
                            args.cell.classList.add('absence-day-cell');
                        } else if (currentDay.working_day && currentDay.work_hours > 0) {
                            args.cell.classList.add('appeared-cell');
                        } else if (currentDay.working_day == true && currentDay.work_hours == 0) {
                            args.cell.classList.add('not-appeared-cell');
                        } else if (currentDay.working_day == false) {
                            if (currentDay.work_hours == 0)
                            args.cell.classList.add('non-working-day-cell');
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
                
            },


            getSelectedDate() {
                return UtilService.convertMoment(this.selectedDate);
            },

            getStartDate() {
                if (this.carnetView == 'DAY')
                    return UtilService.convertMoment(this.selectedDate);
                if (this.carnetView == 'MONTH')
                    return UtilService.convertMoment(this.selectedDate);
                if (this.carnetView == 'MONTH_PER_DAY')
                    return UtilService.convertMoment(this.selectedDate);
                if (this.carnetView == 'YEAR')
                    return UtilService.convertMoment(this.selectedYear + "-01-01");
                if (this.carnetView == 'YEAR_MONTH_PER_DAY')
                    return UtilService.convertMoment(this.selectedYear + "-01-01");
            },

            getCarnetCalendarRangeView() {

                if (this.carnetView == 'DAY')
                    return "day"
                if (this.carnetView == 'MONTH')
                    return "month"
                if (this.carnetView == 'MONTH_PER_DAY')
                    return "month"
                if (this.carnetView == 'YEAR')
                    return "year"
                if (this.carnetView == 'YEAR_MONTH_PER_DAY')
                    return "year"
            },


            getEndDate() {
                return this.getStartDate().add(1, this.getCarnetCalendarRangeView()).subtract(1, 'day');
            },

            async loadCarnet() {
                if (this.perDayView)
                    await this.loadCarnetDays();
                if (this.sumView)
                    await this.loadCarnetSumary();
                if (this.carnetView == 'YEAR_MONTH_PER_DAY')
                    await this.loadCarnetMonths()

            },

            async loadCarnetSumary() {        
                const response = await EmployeesService.getEmployeesCarnet({
                    start_date: this.getStartDate().format("YYYY-MM-DD"), 
                    end_date: this.getEndDate().format("YYYY-MM-DD")});
                this.carnet = response.data;
            },

            async loadCarnetDays() {        
                const response = await EmployeesService.getEmployeesCarnetDays({
                    start_date: this.getStartDate().format("YYYY-MM-DD"), 
                    end_date: this.getEndDate().format("YYYY-MM-DD")});
                this.carnet = response.data;
            },

            async loadCarnetMonths() {        
                const response = await EmployeesService.getEmployeesCarnetMonths({
                    start_date: this.getStartDate().format("YYYY-MM-DD"), 
                    end_date: this.getEndDate().format("YYYY-MM-DD"),
                    year: this.selectedYear
                    });
                this.carnet = response.data;
            },

            async load_data() {
                this.setLoading(true);
                await this.loadActiveEmployees();
                await this.loadCarnet();
                await this.mergeCarnet();
                this.setLoading(false);
            },

            mergeCarnet() {
                this.employees.forEach(employee => {
                    employee.carnet = this.carnet[employee.id];
                    if (!employee.carnet) employee.carnet = {};
                });
                this.employeesCarnet = this.employees.filter(employee => {
                    if (this.getStartDate().format("YYYY-MM-DD") > employee.work_end_date) return false;
                    return Object.keys(employee.carnet).length !== 0
                });
                
                this.$refs.employeesCarnetGrid?.refresh();
            },

            formatDateAndOrTime(str) {
                let date = UtilService.convertMoment(str);
                if (date.format('HH:mm:ss') === "00:00:00") {
                    return UtilService.formatDate(date)
                } else {
                    return UtilService.formatDateTime(date)
                }

            },



            setDefaultDateValue(date) {
                if (this.carnetView == 'DAY')
                    this.selectedDate = UtilService.formatDatePattern(date, "YYYY-MM-DD");
                if (this.carnetView == 'MONTH')
                    this.selectedDate = UtilService.formatDatePattern(date, "YYYY-MM");
                if (this.carnetView == 'MONTH_PER_DAY')
                    this.selectedDate = UtilService.formatDatePattern(date, "YYYY-MM");
                if (this.carnetView == 'YEAR')
                    this.selectedYear = UtilService.formatDatePattern(date, "YYYY");
                if (this.carnetView == 'YEAR_MONTH_PER_DAY')
                    this.selectedYear = UtilService.formatDatePattern(date, "YYYY");
            },

            setLoading(val) {
                if (val) {
                    this.loading = true;
                    this.$refs.employeesCarnetGrid?.showSpinner()
                } else {
                    this.loading = false;
                    this.$refs.employeesCarnetGrid?.hideSpinner()
                }
            },

            changeView() {
                this.setDefaultDateValue(this.getSelectedDate());
                this.load_data();
            },

            changeMPDView() {
                console.log("pass");
            },

            changeDate() {
                this.load_data();
            },

            async setSchedule(employee, workOrder, datetime) {
                let data = {
                    'employee': employee,
                    'work_order': workOrder,
                    'date': datetime
                }

                try {
                    await EmployeesService.addPreWorkOrderEmployee(employee, data);
                } catch (error) {
                    console.log(error);
                }
                this.loadTimelineWorkorders();

            },

            getCurrentYear() {
                return new Date().getFullYear();
            },

            actionCompleteEvent(event) {
                this.setLoading(this.loading);

                if (event.requestType == "columnstate") {
                    this.saveAllColumnsStatus(event.columns)
                }
            },
            
            resizeEvent(event) {
                this.saveColumnStatus(event.column)
            },

            saveColumnStatus(column) {
                this.visibleStates[column.index] = column.visible;
                this.widthStates[column.index] = column.width;

            },

            saveAllColumnsStatus(columns) {
                columns.forEach(column => {
                    this.saveColumnStatus(column);
                });
            },

            createdTable() {
                let columns = this.$refs.employeesCarnetGrid.getColumns()
                console.log(this.$refs.employeesCarnetGrid);
                this.saveAllColumnsStatus(columns);
            },

            clickHandler(args) {

                let exportProperties = {
                    fileName: "new.pdf",
                    theme: {
                        header: {font:  new PdfStandardFont(PdfFontFamily.Helvetica, 11, PdfFontStyle.Bold)},
                        caption: { font: new PdfStandardFont(PdfFontFamily.Helvetica, 9) },
                        record: { font: new PdfStandardFont(PdfFontFamily.Helvetica, 10) }
                    }
                };


                let excelProperties = {
                    fileName: "karnet.xlsx",
                };

                switch (args.item.id) {
                    case 'exportExcel':
                        this.$refs.employeesCarnetGrid.showSpinner();
                        this.$refs.employeesCarnetGrid.excelExport(excelProperties);
                        break;
                    case 'exportPDF':
                        // this.$refs.employeesCarnetGrid.showSpinner();
                        this.$refs.employeesCarnetGrid.pdfExport(exportProperties);
                        break;
                    case 'print':
                        this.$refs.employeesCarnetGrid.print();
                        break;
                
                    default:
                        break;
                }

            },


            pdfExportComplete() {
                this.$refs.employeesCarnetGrid.hideSpinner();
            },
            excelExportComplete() {
                this.$refs.employeesCarnetGrid.hideSpinner();
            },



        },

        created() {
            this.load_data();
        },
    }
    
</script>


<style>


    .carnet-grid.e-grid .e-gridheader { 
        position: -webkit-sticky; 
        position: sticky; 
        top: 0; /* The height of top nav menu. */ 
        /* border-bottom: 1px solid #222; */
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.142);
        z-index: 1; 
    }

    .carnet-grid table .absence-day-cell {
        background: #ffaa49;
    }

    .carnet-grid table tr:hover .absence-day-cell {
        background: #e9b03e !important;
    }


    .carnet-grid table .non-working-day-cell {
        background: #777777;
    }
    
    .carnet-grid table tr:hover .non-working-day-cell {
        background: #666666 !important;
    }
    
    .carnet-grid table .not-appeared-cell {
        background: #ff9898;
    }
    
    .carnet-grid table tr:hover .not-appeared-cell {
        background: #ff8080 !important;
    }


    .carnet-grid .sticky-column {
        position: sticky;
        left: 0;
        z-index: 99;
        background: #fff;

    }

    .carnet-grid .left-150 {
        left: 150px;
    }

    .carnet-grid .e-gridcontent > .e-content {
        height: calc(100vh - 250px) !important;
    }


</style>

<style scoped>

.container {
    min-height: 74vh;
}

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    padding: 16px 30px;
    margin: 0 0 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .white-btn {
    height: 100%;    
    background: #fff;
    width: 100%;
}

.table-title .btn:hover, .table-title .btn:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 4vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 100px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.time-close {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    background: #fff;
}

</style>